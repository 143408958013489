import { Firebase } from './Firebase';
import Constants from 'expo-constants';

let AppStorage;
if (Constants.appOwnership === 'expo') {
    AppStorage = require('./AppStorage.expo').default;
} else {
    AppStorage = require('./AppStorage').default;
}


export { AppStorage, Firebase };
