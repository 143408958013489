import MarkdownIt from 'markdown-it';
// @ts-ignore
import HashtagPlugin from 'markdown-it-hashtag';
// @ts-ignore
import EmojiPlugin from 'markdown-it-emoji';

// custom bbcode plugin
import BBCodePlugin from './bbcode/Plugin';

import tw from 'tailwind-react-native-classnames';


const md = MarkdownIt();

md.use(BBCodePlugin);
md.use(HashtagPlugin);
md.use(EmojiPlugin);

export default md;

export const renderMarkdownToHtml = (markdown: string) => {
    const rendered = md.render(markdown);
    const finalHtml = `<!DOCTYPE html>
    <html>
    
    <head>
        <link rel="stylesheet" href="https://use.typekit.net/kjb3ija.css" />
        <style>
            html,
            body {
                padding-top: 0;
                font-family: proxima-soft, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: ${tw`font-medium`};
                color: #fff;
                background-color: ${tw`bg-gray-700`};
                padding: 0px 0px;
                margin: 0;
                text-size-adjust: auto;
                padding-bottom: 5px;
            }

            a, p, div, span {
                padding-top: 0;
                font-family: proxima-soft, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size:  ${tw`font-medium`};
                color: #fff;
            }
    
            #root {
                padding-bottom: 5px;
            }

            img {
                max-width: 365px;
            }
        </style>
    </head>
    
    <body>
        <div id="root">
            ${rendered}
        </div>
    </body>

    </html>`;

    return finalHtml;
};
