import BBCodeTag from '../BBCodeTag';

import BigTag from './BigTag';
import BoldTag from './BoldTag';
import ColorTag from './ColorTag';
import ItalicsTag from './ItalicsTag';
import SmallTag from './SmallTag';
import StrikethroughTag from './StrikethroughTag';
import SubscriptTag from './SubscriptTag';
import SuperscriptTag from './SuperscriptTag';
import UnderlineTag from './UnderlineTag';
import UrlTag from './UrlTag';

const Tags: BBCodeTag[] = [
    BigTag,
    BoldTag,
    ColorTag,
    ItalicsTag,
    SmallTag,
    StrikethroughTag,
    SubscriptTag,
    SuperscriptTag,
    UnderlineTag,
    UrlTag,
];

export default Tags;