import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

interface AccessTokenPayload {
  accessToken: string;
}

type CurrentAccountState = {
  isAuthRestoring: boolean;
  isSignedIn: boolean;
  isOnboarding: boolean;
  firebaseJwt: string;
  accountId: string;
};

let initialState: CurrentAccountState = {
  isAuthRestoring: true,
  isSignedIn: false,
  isOnboarding: false,
  firebaseJwt: '',
  accountId: '',
};

const decodeSignInTokenState = (
  state: CurrentAccountState,
  accessToken: string
) => {
  state.firebaseJwt = accessToken;
  state.isSignedIn = true;

  // grab the user's accountId from the hasura jwt claims
  const decodedToken = jwt_decode(state.firebaseJwt) as any;
  const hasuraClaims = decodedToken['https://hasura.io/jwt/claims'];
  state.accountId = hasuraClaims['x-hasura-user-id'];
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    onboardAccount(state, action: PayloadAction<AccessTokenPayload>) {
      const { accessToken } = action.payload;

      decodeSignInTokenState(state, accessToken);

      state.isOnboarding = true;

      if (state.isAuthRestoring) {
        state.isAuthRestoring = false;
      }
    },
    completeOnboarding(state, action: PayloadAction<any>) {
      state.isOnboarding = false;
      if (state.isAuthRestoring) {
        state.isAuthRestoring = false;
      }
    },
    signInAccount(state, action: PayloadAction<AccessTokenPayload>) {
      const { accessToken } = action.payload;

      decodeSignInTokenState(state, accessToken);

      if (state.isAuthRestoring) {
        state.isAuthRestoring = false;
      }
    },
    signOutAccount(state) {
      state.accountId = '';
      state.firebaseJwt = '';
      state.isSignedIn = false;

      if (state.isAuthRestoring) {
        state.isAuthRestoring = false;
      }
    },
  },
});

export const {
  onboardAccount,
  completeOnboarding,
  signInAccount,
  signOutAccount,
} = accountSlice.actions;

export default accountSlice.reducer;
