import React from 'react';

import { Image, Pressable, PressableProps, View } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';

import tw from 'tailwind-react-native-classnames';

interface SidebarIconProps extends PressableProps {
  name: string;
}

const SidebarIcon = ({ name, ...pressableProps }: SidebarIconProps) =>
  <View style={tw`my-6 text-center`}>
    <Pressable style={tw`h-8 w-8`} {...pressableProps}>
      <FontAwesome5 name={name} size={22} style={tw`text-gray-300`} />
    </Pressable>
  </View >;

export const SidebarNavigation = () => {
  return (
    <SafeAreaView style={tw`bg-gray-800 w-20 h-full justify-between flex flex-col`}>
      <View style={tw`mt-10 items-center`}>
        <Pressable>
          <Image source={{ uri: 'https://i.imgur.com/Fv2Eq9N.png' }} style={tw`rounded-full w-16 h-16 mb-3`} />
        </Pressable>
        <View style={tw`mt-5 items-center`}>
          <SidebarIcon name={"home"} onPress={() => {
            console.log("pressed home");
          }} />
          <SidebarIcon name={"comments"} />
          <SidebarIcon name={"search"} />
          <SidebarIcon name={"bolt"} />
        </View>
      </View>
      <View style={tw`mb-4 items-center`}>
        <SidebarIcon name={"cogs"} onPress={() => {
          console.log("pressed settings");
        }} />
      </View>
    </SafeAreaView>
  );
};
