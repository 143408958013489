import BBCodeTag from '../BBCodeTag';

import CenterTag from './CenterTag';
import CollapseTag from './CollapseTag';
import HeadingTag from './HeadingTag';
import IndentTag from './IndentTag';
import JustifyTag from './JustifyTag';
import LeftTag from './LeftTag';
import RightTag from './RightTag';

const Tags: BBCodeTag[] = [
    CenterTag,
    CollapseTag,
    HeadingTag,    
    JustifyTag,
    IndentTag,   
    LeftTag,
    RightTag,
];

export default Tags;