import { useMediaQuery } from "react-responsive";

const Breakpoints = {
    mobile: 481,
    tablet: 768,
    laptop: 1024,
    desktop: 2560,
}

export const useResponsive = () => {
    const isMobile = useMediaQuery({ maxWidth: (Breakpoints.mobile - 1) });
    const isTablet = useMediaQuery({ minWidth: Breakpoints.mobile, maxWidth: (Breakpoints.tablet - 1) })
    const isLaptop = useMediaQuery({ minWidth: Breakpoints.tablet, maxWidth: (Breakpoints.laptop - 1) })
    const isDesktop = useMediaQuery({ minWidth: Breakpoints.laptop, maxWidth: (Breakpoints.desktop - 1) })
    const isBigScreen = useMediaQuery({ minWidth: (Breakpoints.desktop) });

    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    return {
        isMobile,
        isTablet,
        isLaptop,
        isDesktop,
        isBigScreen,
        isPortrait,
        isRetina,
    }
}