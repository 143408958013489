import React from 'react';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { Host } from 'react-native-portalize';
import { useSelector } from 'react-redux';

import { AppNavigator, SignedOutNavigator } from 'navigation';
import { RootState } from 'redux-config';

/*
 * Navigation theming: https://reactnavigation.org/docs/en/next/themes.html
 */
const navigatorTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    // prevent layout blinking when performing navigation
    background: 'transparent',
  },
};

const prefixes = ['https://personify.xyz', 'psfy://'];

const authLinks = {
  prefixes,
  config: {
    screens: {
      SignUp: { path: 'signup' },
      SignIn: { path: 'login' },
    }
  },
};

const appLinks = {
  prefixes,
  config: {
    screens: {
      Home: {
        path: '/',
        screens: {
          Timeline: '',
          Chat: 'chat',
          Discover: 'discover',
          Notifications: 'notifications'
        }
      }
    }
  },
};

export const AppShell = () => {
  const isSignedIn = useSelector(
    (state: RootState) => state.accountReducer.isSignedIn
  );

  console.log(`signed in: ${isSignedIn}`)

  return (
    <NavigationContainer
      linking={isSignedIn ? appLinks : authLinks}
      theme={navigatorTheme}
    >
      <Host>{isSignedIn ? <AppNavigator /> : <SignedOutNavigator />}</Host>
    </NavigationContainer>
  );
};
