import BBCodeTag from '../BBCodeTag';

import Token from 'markdown-it/lib/token';
import Renderer from 'markdown-it/lib/renderer';

function getArg(attrs: string[][]) {
  if (!attrs || attrs.length < 1) {
    return null;
  }

  for (var ii = 0; ii < attrs.length; ii++) {
    var att = attrs[ii];
    if (att && att.length > 1) {
      if (att[0] === 'arg') {
        return att[1];
      }
    }
  }

  return null;
}

const ColorTag: BBCodeTag = {
  name: 'text_color',
  tag: 'color',
  hasArg: true,
  render: (
    tokens: Token[],
    index: number,
    options: any,
    env: any,
    self: Renderer
  ) => {
    var token = tokens[index];

    var result = ['<'];

    if (token.nesting === -1) {
      result.push('/');
    }

    result.push('span');

    if (token.nesting === 1) {
      var color = getArg(token.attrs);

      if (color) {
        result.push(' style="color:', color, ';"');
      }
    }
    result.push('>');

    return result.join('');
  },
};

export default ColorTag;
