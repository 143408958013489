import React, { useState, Dispatch, useEffect } from 'react';
import ExpoAppLoading from 'expo-app-loading';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import { Asset } from 'expo-asset';
import { connect, DispatchProp, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { Firebase } from 'services';
import {
  signInAccount,
  signOutAccount,
  onboardAccount,
} from 'accounts/AccountSlice';
import { useApolloClient } from '@apollo/client';
import { queries } from 'accounts/GraphQL';
import jwt_decode from 'jwt-decode';
import { RootState } from 'redux-config';

export type TaskResult = [string, any];
export type Task = (
  dispatch: Dispatch<AnyAction>
) => Promise<TaskResult | void>;

export interface ApplicationLoaderProps {
  tasks?: Task[];
  initialConfig?: Record<string, any>;
  placeholder?: (props: { loading: boolean }) => React.ReactElement;
  children: (config: any) => React.ReactElement;
  dispatch: Dispatch<AnyAction>;
}

export const LoadFontsTask = async (fonts: {
  [key: string]: number;
}): Promise<TaskResult | void> => {
  await Font.loadAsync(fonts);
};

export const LoadAssetsTask = async (
  assets: number[]
): Promise<TaskResult | void> => {
  const tasks: Promise<TaskResult | void>[] = assets.map(
    async (source: number): Promise<TaskResult | void> => {
      Asset.fromModule(source).downloadAsync();
    }
  );

  await Promise.all(tasks);
};

/*
 * Prevent splash screen from hiding since it is controllable by AppLoading component.
 */
SplashScreen.preventAutoHideAsync();

/**
 * Loads application configuration and returns content of the application when done.
 *
 * @property {Task[]} tasks - Array of tasks to prepare application before it's loaded.
 * A single task should return a Promise with value and a by which this value is accessible.
 *
 * @property {any} fallback - Fallback configuration that is used as default application configuration.
 * May be useful at first run.
 *
 * @property {(props: { loaded: boolean }) => React.ReactElement} placeholder - Element to render
 * while application is loading.
 *
 * @property {(result: any) => React.ReactElement} children - Should return Application component
 */
const AppLoadingComponent = (
  props: ApplicationLoaderProps
): React.ReactElement => {
  const isAuthRestoring = useSelector(
    (state: RootState) => state.accountReducer.isAuthRestoring
  );

  const client = useApolloClient();
  const [tasksCompleted, setTasksCompleted] = useState<boolean>(false);
  const [hasAuthRestored, setHasAuthRestored] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const loadingResult = props.initialConfig || {};

  const onTasksFinish = (): void => {
    setTasksCompleted(true);
  };

  const saveTaskResult = (result: TaskResult | void): void => {
    if (result) {
      loadingResult[result[0]] = result[1];
    }
  };

  const createRunnableTask = async (task: Task): Promise<TaskResult | void> => {
    return task(props.dispatch).then(saveTaskResult);
  };

  const startTasks = (): Promise<any> => {
    if (props.tasks) {
      return Promise.all(props.tasks.map(createRunnableTask));
    }
    return Promise.resolve();
  };

  const renderLoadingElement = (): React.ReactElement => (
    <ExpoAppLoading
      startAsync={startTasks}
      onFinish={onTasksFinish}
      autoHideSplash={false}
      onError={(error: Error) => { console.log(error); }}
    />
  );

  useEffect(() => {
    Firebase.onAuthStateChanged(
      (token) => {
        const decodedToken = jwt_decode(token) as any;
        const hasuraClaims = decodedToken['https://hasura.io/jwt/claims'];
        const accountId = hasuraClaims['x-hasura-user-id'];

        client
          .query({ query: queries.accountInfo, variables: { accountId } })
          .then((response) => {
            const {
              account: { is_onboarded },
            } = response.data;

            if (is_onboarded) {
              props.dispatch(signInAccount({ accessToken: token }));
            } else {
              props.dispatch(onboardAccount({ accessToken: token }));
            }
          });
      },
      () => {
        props.dispatch(signOutAccount());
      },
      (err) => {
        throw err;
      }
    );
  }, []);

  // Wait for Firebase to restore the auth token +
  // Hasura to return account state
  if (!hasAuthRestored && !isAuthRestoring) {
    setHasAuthRestored(true);
  }

  // If we are still loading, and the following has completed:
  // - Restore auth details from Firebase + Hasura
  // - All loading tasks have completed
  // Finish loading and hide the splash screen
  if (isLoading && tasksCompleted && hasAuthRestored) {
    setIsLoading(false);
    SplashScreen.hideAsync();
  }

  return (
    <React.Fragment>
      {isLoading ? renderLoadingElement() : props.children(loadingResult)}
      {/* this seems busted  */}
      {props.placeholder && isLoading && props.placeholder({ loading: isLoading })}
    </React.Fragment>
  );
};

export const AppLoading = connect()(AppLoadingComponent);
