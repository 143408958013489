import { configureStore } from '@reduxjs/toolkit';

import RootReducer from './RootReducer';

const ReduxStore = configureStore({
  reducer: RootReducer,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./RootReducer', () => {
    const newRootReducer = require('./RootReducer').default;
    ReduxStore.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof ReduxStore.dispatch;

export default ReduxStore;
