import React, { useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { Button, Input, ScreenRoot } from 'components';
import { Firebase } from 'services';
import { getLocalizedString } from 'localization';
import tw from 'tailwind-react-native-classnames';

import { mutations } from '../GraphQL';
import { onboardAccount } from '../AccountSlice';

// TODO: Strongly type
export const SignUpScreen = ({ navigation }: any): React.ReactElement => {
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const [signUp] = useMutation(mutations.signup);

  const onSignUpButtonPress = async () => {
    if (!emailAddress || !password) {
      console.error('validation needed');
      return;
    }

    // todo: verify confirm passwords

    if (!termsAccepted) {
      // todo: another validation error
    }

    console.log('doing sign up');

    const signUpResponse = await signUp({
      variables: { emailAddress: emailAddress, password },
    });

    console.log('did sign up');
    console.log(signUpResponse);

    // TODO: maybe this should throw on fail?
    const { accessToken } =
      (await Firebase.loginUser(emailAddress, password)) || {};

    if (!accessToken) {
      throw new Error('loginUser() returned undefined');
    }

    dispatch(onboardAccount({ accessToken }));
  };

  const onSignInButtonPress = (): void => {
    navigation && navigation.navigate('SignIn');
  };

  return (
    <ScreenRoot avoidKeyboard={true} scrollContainerStyle={tw`items-center flex-grow justify-center`}>
      <View style={tw`w-full max-w-xs bg-gray-800 rounded p-5 items-center`}>
        <View>
          <Text style={tw`text-gray-300 text-xl font-medium mt-4`}>
            {getLocalizedString('signUpHeaderText')}
          </Text>
          <View style={tw`flex flex-row pt-2 pl-2`}>
            <Text style={tw`text-sm text-gray-300`}>{getLocalizedString('signInPrompt')}</Text>
            <Text style={tw`text-sm pl-2 text-gray-400`} onPress={onSignInButtonPress}>{getLocalizedString('signInAction')}</Text>
          </View>
        </View>
        <View style={tw`w-full pt-5`}>
          <Input
            label={getLocalizedString('emailAddress')}
            placeholder={getLocalizedString('emailAddress')}
            value={emailAddress}
            onChangeText={setEmailAddress}
          />
          <Input
            label={getLocalizedString('password')}
            placeholder={getLocalizedString('password')}
            value={password}
            secureTextEntry={true}
            onChangeText={setPassword}
          />
          <Input
            label={getLocalizedString('confirmPassword')}
            placeholder={getLocalizedString('confirmPassword')}
            value={confirmPassword}
            secureTextEntry={true}
            onChangeText={setConfirmPassword}
          />
          <Button
            text={getLocalizedString('signUpButtonText')}
            onPress={onSignUpButtonPress}
          />
        </View>
      </View>
    </ScreenRoot>
  );
};
