import React from 'react';
import { TextInput, View, Text, TextInputProps } from 'react-native';

import tw from 'tailwind-react-native-classnames';

interface IInputProps extends TextInputProps {
    label?: string;
}

export const Input = ({ label, ...inputProps }: IInputProps) =>
    <View>
        {label && <Text style={tw`mb-2 text-gray-300`}>{label}</Text>}
        <TextInput style={tw`w-full p-2 mb-6 text-gray-400 rounded border-2 border-gray-500`} placeholderTextColor={"gray"} {...inputProps} />
    </View>;