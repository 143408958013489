import React, { useState } from 'react';
import { View, Text } from 'react-native';
import tw from 'tailwind-react-native-classnames';

import { getLocalizedString } from 'localization';
import { Firebase } from 'services';
import { signInAccount, onboardAccount } from '../AccountSlice';
import { useDispatch } from 'react-redux';
import { queries } from '../GraphQL';
import { useLazyQuery } from '@apollo/client';
import { Button, Input, ScreenRoot } from 'components';

// TODO: strongly type this
export const SignInScreen = ({ navigation }: any): React.ReactElement => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const [accessToken, setAccessToken] = useState<string>('');

  const [
    getAccountInfo,
    {
      data: accountInfo,
      loading: isAccountInfoLoading,
      error: accountInfoError,
    },
  ] = useLazyQuery(queries.accountInfo);

  const onSignInButtonPress = async () => {
    setIsLoading(true);

    if (!emailAddress || !password) {
      console.error('validation needed');
      return;
    }

    const { accessToken: loginToken, accountId } =
      (await Firebase.loginUser(emailAddress, password)) || {};

    if (!loginToken) {
      throw new Error('loginUser() returned undefined');
    }

    setAccessToken(loginToken);

    getAccountInfo({
      variables: {
        accountId,
      },
    });
  };

  // navigate to the sign up page on press
  const onSignUpButtonPress = (): void => {
    navigation && navigation.navigate('SignUp');
  };

  const onPasswordIconPress = (): void => {
    setPasswordVisible(!passwordVisible);
  };


  if (!isAccountInfoLoading && accountInfo) {
    if (accountInfoError) {
      console.log('failed to get account info');
      throw accountInfoError;
    }

    const { is_onboarded } = accountInfo;

    if (is_onboarded) {
      console.log('logging in user');
      dispatch(signInAccount({ accessToken }));
    } else {
      dispatch(onboardAccount({ accessToken }));
    }
  }

  return (
    <ScreenRoot avoidKeyboard={true} scrollContainerStyle={tw`items-center flex-grow justify-center`}>
      <View style={tw`w-full max-w-xs bg-gray-800 rounded p-5 items-center`}>
        <View>
          <Text style={tw`text-gray-300 text-xl font-medium mt-4`}>
            {getLocalizedString('signInHeaderText')}
          </Text>
          <View style={tw`flex flex-row pt-2 pl-2`}>
            <Text style={tw`text-sm text-gray-300`}>{getLocalizedString('createAccountPrompt')}</Text>
            <Text style={tw`text-sm pl-2 text-gray-400`} onPress={onSignUpButtonPress}>{getLocalizedString('createAccountAction')}</Text>
          </View>
        </View>
        <View style={tw`w-full pt-5`}>
          <Input
            label={getLocalizedString('emailAddress')}
            placeholder={getLocalizedString('emailAddress')}
            value={emailAddress}
            onChangeText={setEmailAddress}
          />
          <Input
            label={getLocalizedString('password')}
            placeholder={getLocalizedString('password')}
            value={password}
            secureTextEntry={!passwordVisible}
            onChangeText={setPassword}
          />
          <Button
            text={getLocalizedString('signInButton')}
            onPress={onSignInButtonPress}
          />
        </View>
      </View>
    </ScreenRoot>
  );
};
