import { AsyncStorage, LogBox } from 'react-native';

const ACCESS_TOKEN_KEY = 'userToken';

export class AppStorage {
  static getAccessToken = async (): Promise<string | null> => {
    return await AsyncStorage.getItem(ACCESS_TOKEN_KEY);
  };

  static setAccessToken = async (token: string): Promise<void> => {
    await AsyncStorage.setItem(ACCESS_TOKEN_KEY, token);
  };
}

/**
 * In a Bare React Native project you should use
 * https://github.com/react-native-community/async-storage
 *
 * However, Expo runs AsyncStorage exported from react-native.
 * Just to save application bundle size, we still using this one.
 */
//LogBox.ignoreLogs(['AsyncStorage has been extracted']);
