import BBCodeTag from '../BBCodeTag';

import Token from 'markdown-it/lib/token';
import Renderer from 'markdown-it/lib/renderer';

// TODO: Clean this up, no copypasta
function getArg(attrs: string[][]) {
  if (!attrs || attrs.length < 1) {
    return null;
  }

  for (var ii = 0; ii < attrs.length; ii++) {
    var att = attrs[ii];
    if (att && att.length > 1) {
      if (att[0] === 'arg') {
        return att[1];
      }
    }
  }

  return null;
}

const UrlTag: BBCodeTag = {
  name: 'text_url',
  tag: 'url',
  hasArg: true,
  render: (
    tokens: Token[],
    index: number,
    options: any,
    env: any,
    self: Renderer
  ) => {
    const token = tokens[index];

    let result = ['<'];

    if (token.nesting === -1) {
      result.push('/');
    }

    result.push('a');

    if (token.nesting === 1) {
      const url = getArg(token.attrs);

      if (url) {
        result.push(' href="', url, '"');
      }
    }
    result.push('>');

    return result.join('');
  },
};

export default UrlTag;
