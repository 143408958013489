// run side effects
import 'react-native-gesture-handler';
import 'react-native-match-media-polyfill';

import React from 'react';
import { Provider, useDispatch } from 'react-redux';
import {
  AppearanceProvider,
  Appearance,
  AppearancePreferences,
} from 'react-native-appearance';

import { SafeAreaProvider } from 'react-native-safe-area-context';

// Pull in the root of the app based on platform
import {
  apolloClient,
  AppShell,
  AppLoading,
  SplashScreen
} from 'app';
import { ReduxStore } from 'redux-config';
import { ApolloProvider } from '@apollo/client';
import { configureLocalization } from 'localization';

// Configure localization support!
configureLocalization();


const App = () => {
  // TODO: GitHub #1 - react-native-appearance appears broken
  React.useEffect(() => {
    console.log('adding callback');
    const subscription = Appearance.addChangeListener(
      (preferences: AppearancePreferences): void => {
        console.log('got preferences');
        console.log(JSON.stringify(preferences));
      }
    );

    return () => {
      console.log('removing callback');
      subscription.remove();
    };
  }, []);

  return (
    <AppShell />
  );
};



export default () => (
  <AppearanceProvider>
    <ApolloProvider client={apolloClient}>
      <Provider store={ReduxStore}>
        <SafeAreaProvider>
          <AppLoading
            tasks={[]}
            initialConfig={{}}
            placeholder={SplashScreen}
          >
            {(props) => <App {...props} />}
          </AppLoading>
        </SafeAreaProvider>
      </Provider>
    </ApolloProvider>
  </AppearanceProvider>
);
