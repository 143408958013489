import React from 'react';
import { Text } from 'react-native';
import tw from 'tailwind-react-native-classnames';

import { ScreenRoot } from 'components';

export const ChatList = () => {
    return (
        <ScreenRoot avoidKeyboard={true} scrollContainerStyle={tw`items-center flex-grow justify-center`}>
            <Text>Chats go here</Text>
        </ScreenRoot>
    );
};