import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  from,
} from '@apollo/client';
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from '@apollo/link-ws';
import { setContext } from '@apollo/link-context';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { OperationDefinitionNode } from 'graphql';
import { Firebase } from 'services';

const GRAPHQL_ENDPOINT = 'api.personify.xyz/v1/graphql';

// TODO: Auth headers
const authLink = setContext((_, { headers }) => {
  //it will always get unexpired version of the token
  return Firebase.getUserToken().then((token) => {
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    } else {
      return headers;
    }
  });
});

const httpLink = from([
  authLink,
  new HttpLink({
    uri: `https://${GRAPHQL_ENDPOINT}`,
  }),
]);

const client = new SubscriptionClient(`wss://${GRAPHQL_ENDPOINT}`, {
  reconnect: true,
});

const wsLink = from([authLink, new WebSocketLink(client)]);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(
        query
      ) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  ),
});
