import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { KeyboardAvoidingScrollView } from './KeyboardAvoidingScrollView';

import tw from 'tailwind-react-native-classnames';
import { View } from 'react-native';

type ScreenRootProps = {
    children: React.ReactNode;
    avoidKeyboard?: boolean;
    scrollContainerStyle?: any;
}

export const ScreenRoot = ({ avoidKeyboard, scrollContainerStyle, children }: ScreenRootProps) => {
    return (
        <View style={tw`h-full flex bg-gray-700`}>
            {avoidKeyboard ?
                <KeyboardAvoidingScrollView contentContainerStyle={scrollContainerStyle}>
                    {children}
                </KeyboardAvoidingScrollView> :
                <>
                    {children}
                </>
            }
        </View>
    )
};