import React from 'react';

import { renderMarkdownToHtml } from 'markdown';

interface IMarkdownViewProps {
    markdown: string;
}

export const MarkdownView = ({ markdown }: IMarkdownViewProps) => {
    return <div
        dangerouslySetInnerHTML={{ __html: renderMarkdownToHtml(markdown) }}
    >
    </div>
}