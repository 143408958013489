import BBCodeTag from '../BBCodeTag';

import Token from 'markdown-it/lib/token';
import Renderer from 'markdown-it/lib/renderer';

function getArg(attrs: string[][]) {
  if (!attrs || attrs.length < 1) {
    return null;
  }

  for (var ii = 0; ii < attrs.length; ii++) {
    var att = attrs[ii];
    if (att && att.length > 1) {
      if (att[0] === 'arg') {
        return att[1];
      }
    }
  }

  return null;
}

// TODO: Can we do nesting internally to get some styling for collapse?
const CollapseTag: BBCodeTag = {
  name: 'text_collapse',
  tag: 'collapse',
  hasArg: true,
  render: (
    tokens: Token[],
    index: number,
    options: any,
    env: any,
    self: Renderer
  ) => {
    const token = tokens[index];

    let result = ['<'];

    if (token.nesting === -1) {
      result.push('/');
    }

    result.push('details');

    if (token.nesting === 1) {
      const spoilerWarning = getArg(token.attrs);

      if (spoilerWarning) {
        result.push('><summary>', spoilerWarning, '</summary>');
        return result.join('');
      }
    }

    result.push('>');

    return result.join('');
  },
};

export default CollapseTag;
