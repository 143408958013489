import BBCodeTag from '../BBCodeTag';

import Token from 'markdown-it/lib/token';
import Renderer from 'markdown-it/lib/renderer';

const IndentTag: BBCodeTag = {
  name: 'text_indent',
  tag: 'indent',
  hasArg: false,
  render: (
    tokens: Token[],
    index: number,
    options: any,
    env: any,
    self: Renderer
  ) => {
    var token = tokens[index];

    var result = ['<'];

    if (token.nesting === -1) {
      result.push('/');
    }

    result.push('div');

    if (token.nesting === 1) {
      result.push(' style="margin-left:5%;"');
    }
    result.push('>');

    return result.join('');
  },
};

export default IndentTag;
