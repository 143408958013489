export default {
  welcome: 'Hello!',
  emailAddress: 'Email Address',
  password: 'Password',
  forgotPassword: 'Forgot your password?',
  signInHeaderText: 'Welcome Back!',
  signInButton: 'SIGN IN',
  createAccountPrompt: "Don't have an account?",
  createAccountAction: 'Create one now!',
  signUpHeaderText: 'Nice to meet ya!',
  signUpTermsText: 'I have read and agree to the Terms & Conditions.',
  signUpButtonText: 'SIGN UP',
  confirmPassword: 'Confirm Password',
  signInPrompt: 'Already have an account?',
  signInAction: 'Sign in now!',
};
