import React from 'react';
import { Pressable, PressableProps, Text } from 'react-native';
import tw from 'tailwind-react-native-classnames';

interface IButtonProps extends PressableProps {
    text: string;
}

export const Button = ({ text, ...pressProps }: IButtonProps) =>
    <Pressable style={tw`w-full bg-indigo-600 py-2 items-center rounded-md`} {...pressProps}>
        <Text style={tw`text-white font-medium`}>{text}</Text>
    </Pressable>;