import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { RootState } from 'redux-config';
import { SidebarNavigation } from 'components';
import { View } from 'react-native';

import { FontAwesome5 } from '@expo/vector-icons';

import tw from 'tailwind-react-native-classnames';
import { HomeScreen } from 'home/screens';
import { useResponsive } from 'hooks';
import { ChatList } from 'chat/pages';

const Tab = createBottomTabNavigator();

function AppBottomTabs() {
  const { isMobile } = useResponsive();

  return (
    <Tab.Navigator
      tabBarOptions={{
        style: tw.style('bg-gray-800 border-0 border-gray-600', { shadowColor: 'transparent', }),
        showLabel: false,
        activeTintColor: '#000000'
      }}>
      <Tab.Screen
        name={'Timeline'}
        component={HomeScreen}
        options={{
          tabBarVisible: isMobile,
          tabBarIcon: () => {
            return <FontAwesome5 name={'home'} size={22} style={tw`text-gray-300`} />;
          }
        }} />
      <Tab.Screen
        name={'Chat'}
        component={ChatList}
        options={{
          tabBarVisible: isMobile,
          tabBarIcon: () => {
            return <FontAwesome5 name={'comments'} size={22} style={tw`text-gray-300`} />;
          }
        }} />
      <Tab.Screen
        name={'Discover'}
        component={HomeScreen}
        options={{
          tabBarVisible: isMobile,
          tabBarIcon: () => {
            return <FontAwesome5 name={'search'} size={22} style={tw`text-gray-300`} />;
          }
        }} />
      <Tab.Screen
        name={'Notifications'}
        component={HomeScreen}
        options={{
          tabBarVisible: isMobile,
          tabBarIcon: () => {
            return <FontAwesome5 name={'bolt'} size={22} style={tw`text-gray-300`} />;
          }
        }} />
    </Tab.Navigator>);
}

const Stack = createStackNavigator();

export default function AppNavigator() {
  const { isMobile } = useResponsive();

  return (
    <View style={tw`w-full h-full flex flex-row bg-gray-700`}>
      {!isMobile ? <SidebarNavigation /> : null}
      <Stack.Navigator
        headerMode='screen'
        screenOptions={{
          headerStyle: tw.style('bg-gray-800 border-0 border-gray-600', { shadowColor: 'transparent' }),
          headerTintColor: '#fff',
          headerBackImage: () => (<FontAwesome5 name={'arrow-left'} size={16} />)
        }}>
        <Stack.Screen name={'Home'} component={AppBottomTabs} />
      </Stack.Navigator>
    </View>
  );
}
