import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { SignInScreen, SignUpScreen } from 'accounts/screens';

const Stack = createStackNavigator();

export default function SignedOutNavigator() {
  return (
    <Stack.Navigator initialRouteName='SignIn' headerMode='none'>
      <Stack.Screen name='SignIn' component={SignInScreen} />
      <Stack.Screen name='SignUp' component={SignUpScreen} />
    </Stack.Navigator>
  );
}
