import React from 'react';
import { View, Text, Image, Pressable, PressableProps } from 'react-native';
import tw from 'tailwind-react-native-classnames';

import { FontAwesome5 } from '@expo/vector-icons';

import { MarkdownView } from 'components';
import { useResponsive } from 'hooks';

interface IFeedItemButtonProps extends PressableProps {
    fontAwesomeIcon: string;
}

const FeedItemButton = ({ fontAwesomeIcon, ...pressableProps }: IFeedItemButtonProps) => {
    return (
        <Pressable style={tw`flex-1 text-center py-2 m-2`} {...pressableProps}>
            <FontAwesome5 name={fontAwesomeIcon} size={16} style={tw`text-gray-500`} />
        </Pressable>);
}

interface IFeedItemProps extends PressableProps {
    // todo this should actually be a post id
    // postId: string;
    markdown: string;
}

export const FeedItem = ({ markdown, ...pressableProps }: IFeedItemProps) => {

    const { isMobile, isTablet } = useResponsive();

    return (
        <Pressable style={isTablet ? tw`w-5/6 mx-24` : tw`w-5/6`}>
            <View style={tw`flex flex-row flex-shrink-0 p-4 pb-0`}>
                <View>
                    <Image source={{ uri: 'https://i.imgur.com/Fv2Eq9N.png' }} style={tw`rounded-full w-14 h-14`} />
                </View>
                <View style={tw`flex items-center`}>
                    <View style={tw`ml-3`}>
                        <Text style={tw`text-base leading-6 font-medium text-white`}>Uzumaki Naruto</Text>
                        <Text style={tw`text-sm leading-5 font-medium text-gray-400`}>@dattebayo</Text>
                    </View>
                </View>
            </View>
            <View style={tw`pl-16`}>
                <MarkdownView
                    style={tw`w-full flex-shrink`}
                    markdown={markdown}
                />
                <View style={tw`flex flex-row w-2/3 items-center`}>
                    <FeedItemButton fontAwesomeIcon={'comment-dots'} />
                    <FeedItemButton fontAwesomeIcon={'retweet'} />
                    <FeedItemButton fontAwesomeIcon={'smile'} />
                    <FeedItemButton fontAwesomeIcon={'ellipsis-h'} />
                </View>
            </View>
        </Pressable>);
}