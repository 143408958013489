import firebase from 'firebase/app';
import 'firebase/auth';
import jwt_decode from 'jwt-decode';

firebase.initializeApp({
  apiKey: 'AIzaSyAmpKq1Eb97IrZMKBTBWUG6bewr9m8afX0',
  authDomain: 'personify-app-7824e.firebaseapp.com',
  databaseURL: 'https://personify-app-7824e.firebaseio.com',
  projectId: 'personify-app-7824e',
  storageBucket: 'personify-app-7824e.appspot.com',
  messagingSenderId: '174972895593',
  appId: '1:174972895593:web:83f61e0b7ba64e191e7df8',
  measurementId: 'G-755T5VJNNJ',
});

export class Firebase {
  public static async getUserToken() {
    const accessToken = await firebase.auth().currentUser?.getIdToken();
    return accessToken;
  }

  public static async loginUser(emailAddress: string, password: string) {
    // tell firebase to persist locally on login
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    const signInResult = await firebase
      .auth()
      .signInWithEmailAndPassword(emailAddress, password);

    const accessToken = await signInResult.user?.getIdToken();

    if (!accessToken) {
      return undefined;
    }

    const decodedToken = jwt_decode(accessToken) as any;
    const hasuraClaims = decodedToken['https://hasura.io/jwt/claims'];
    const accountId = hasuraClaims['x-hasura-user-id'];

    return { accessToken, accountId };
  }

  public static async signoutUser() {
    await firebase.auth().signOut();
  }

  public static onAuthStateChanged(
    loggedInCallback: (accessToken: string) => void,
    loggedOutCallback: () => void,
    errorCallback: (err: Error) => void
  ) {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in.
        // Do stuff
        const accessToken = await firebase.auth().currentUser?.getIdToken();

        if (accessToken === undefined) {
          errorCallback(new Error('getIdToken() returned undefined'));
        } else {
          loggedInCallback(accessToken);
        }
      } else {
        // User is signed out.
        // Log them outa there!
        loggedOutCallback();
      }
    });
  }
}
