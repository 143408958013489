import React from 'react';
import { ScrollView } from 'react-native';
import tw from 'tailwind-react-native-classnames';

import { ScreenRoot } from 'components';
import { FeedItem } from 'feeds/components';


export const HomeScreen = () => {
    return (
        <ScreenRoot avoidKeyboard={true} scrollContainerStyle={tw`items-center flex-grow justify-center`}>
            <ScrollView style={tw`w-full`} contentContainerStyle={tw`border-gray-600 border-b-2`}>
                <FeedItem markdown={'Damn girl that **ass** can _fart_'} />
                <FeedItem markdown={'Damn girl that **ass** can _fart_'} />
                <FeedItem markdown={'Damn girl that **ass** can _fart_'} />
                <FeedItem markdown={'Damn girl that **ass** can _fart_'} />
                <FeedItem markdown={'Damn girl that **ass** can _fart_'} />
                <FeedItem markdown={'Damn girl that **ass** can _fart_'} />
                <FeedItem markdown={'Damn girl that **ass** can _fart_'} />
                <FeedItem markdown={'Damn girl that **ass** can _fart_'} />
            </ScrollView>
        </ScreenRoot>
    );
};