import React from 'react';

import { ScreenRoot } from 'components';
import { View, Text } from 'react-native';

import tw from 'tailwind-react-native-classnames';

export const SplashScreen = () =>
    <ScreenRoot scrollContainerStyle={tw`items-center flex-grow justify-center`}>
        <View style={tw`w-full max-w-xs bg-indigo-100 rounded p-5 items-center`}>
            <Text>Placeholder</Text>
        </View>
    </ScreenRoot>;