import MarkdownIt, { Rule } from 'markdown-it';
import Token from 'markdown-it/lib/token';

import { BBCodeTags } from './tags';
import BBCodeTag from './tags/BBCodeTag';

type TagType = 'open' | 'close';

// TODO: figure out why I take in name and do nothing?
const BBCodePlugin = (md: MarkdownIt, name: string, options: any) => {
  options = options || {};

  const CreateRuleForTag = (tag: BBCodeTag, type: TagType) => {
    const TagRule: Rule = (state: any) => {
      let token: Token;
      const isOpen = type === 'open';
      const max = state.maxPos;
      const start = state.pos;

      // decide which bbcode regex to use
      const regexStr =
        type === 'open'
          ? tag.hasArg
            ? `\\[${tag.tag}=(\\w+)\\]`
            : `\\[${tag.tag}\\]`
          : `\\[/${tag.tag}\\]`;

      const tagRegex = new RegExp(regexStr);

      // verify we're actually parsing out a thing
      if (state.src.charCodeAt(start) !== 0x5b) {
        return false;
      }

      let content = state.src.slice(start);

      const result = tagRegex.exec(content);

      if (!result) {
        return false;
      }

      if (type === 'open') {
        if (!content.startsWith(result[0])) {
          return false;
        }
      } else if (type === 'close') {
        if (!content.endsWith(result[0])) {
          return false;
        }
      }

      if (options.trace) {
        console.log(`parsing tag: bbcode_${tag.name}_${type}`);
        console.log(`parsing content: ${content}`);
        console.log(`regex result: ${result[0]}`);
      }

      // determine the token's nesting state for md-it
      const nesting: Number = isOpen ? 1 : -1;

      // move the max Position to be offset by the length we'll take
      // TODO: make sure we're moving correctly here- i wonder if some of the nesting pains come from this
      state.posMax = start + result[0].length;

      // push this token state
      token = state.push(`bbcode_${tag.name}_${type}`, 'span', nesting);
      token.markup = `[${tag.tag}]`;

      // if we're an open tag, check if we have an argument
      if (isOpen) {
        if (tag.hasArg) {
          token.attrPush(['arg', result[1]]);
        }
      }

      state.pos = state.posMax;
      state.posMax = max;
      return true;
    };
    return TagRule;
  };

  BBCodeTags.forEach((tag: BBCodeTag) => {
    md.inline.ruler.push(
      `bbcode_${tag.name}_open`,
      CreateRuleForTag(tag, 'open')
    );
    md.inline.ruler.push(
      `bbcode_${tag.name}_close`,
      CreateRuleForTag(tag, 'close')
    );
    md.renderer.rules[`bbcode_${tag.name}_open`] = tag.render; // todo: allow overrides
    md.renderer.rules[`bbcode_${tag.name}_close`] = tag.render; // todo: allow overrides
  });
};

export default BBCodePlugin;
