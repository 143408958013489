import { gql } from '@apollo/client';

export const mutations = {
  signup: gql`
    mutation signup($emailAddress: String!, $password: String!) {
      signup(signupForm: { emailAddress: $emailAddress, password: $password }) {
        accountId
      }
    }
  `,
};

export const queries = {
  accountInfo: gql`
    query getAccountInfo($accountId: uuid!) {
      account(id: $accountId) {
        is_verified
        is_onboarded
        default_persona_id
      }
    }
  `,
};
